@import "../../../styles/utils/mixins";
@import "../../../styles/utils/variables";

.root {
  padding-top: 24px;
  padding-bottom: 24px;
  @include onLaptop {
    padding-top: 96px;
    padding-bottom: 96px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  @include onLaptop {
    flex-direction: row;
  }
}

// Experience
.experience {
  width: 100%;
  position: relative;
  order: 3;
  @include onLaptop {
    order: initial;
    width: 50%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding-right: 32px;
  }
  @include onDesktop {
    padding-right: 0;
  }
}

.experienceTitle {
  color: white;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.15px;
  margin-bottom: 16px;
  @include onLaptop {
    font-size: 20px;
    line-height: 32px;
  }
}

.experienceGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.experienceItem {
  display: flex;
  background-color: rgba($primary, 0.12);
  border-radius: 24px;
  color: white;
  padding: 8px 20px 8px 16px;
}
.experienceItemIcon {
  color: $primary;
  margin-right: 8px;
}
.experienceItemLabel {
  color: white;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.1px;
}

.info {
  width: 100%;
  order: 1;
  @include onLaptop {
    order: initial;
    width: 50%;
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: rgba(white, 0.5);
  margin-top: 32px;
  margin-bottom: 32px;
  order: 2;
  @include onLaptop {
    height: initial;
    margin-top: initial;
    margin-bottom: initial;
    order: initial;

    width: 1px;
    margin-right: 34px;
    margin-left: 34px;
  }
  @include onDesktop {
    margin-right: 74px;
    margin-left: 74px;
  }
}

.mobileDots {
  display: flex;
  justify-content: flex-end;
  padding-top: 32px;
  @include onTablet {
    padding-top: 16px;
  }
  @include onLaptop {
    display: none;
  }
}
.desktopDots {
  display: none;
  @include onLaptop {
    display: block;
    margin-bottom: -64px;
    margin-left: -88px;
  }
  @include onDesktop {
    margin-left: -170px;
  }
}
