.container {
  max-width: 1164px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 24px;
  padding-left: 24px;
}

.big-container {
  max-width: 1546px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 24px;
  padding-left: 24px;
}
