@import "../../../styles/utils/mixins";
@import "../../../styles/utils/variables";

.root {
  display: flex;
  justify-content: center;
  align-items: center;

  padding-top: 24px;
  padding-bottom: 48px;
  @include onLaptop {
    padding-top: 96px;
    padding-bottom: 96px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  @include onLaptop {
    flex-direction: row;
  }
}

.projectImages {
  width: 100%;
  order: 2;
  @include onLaptop {
    width: 66.66%;
    order: 1;
    margin-right: 64px;
    margin-left: -64px;
  }
}

.projectImagesWrapper {
  position: relative;
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 32px;
  margin-bottom: 48px;
  @include onLaptop {
    margin-bottom: 0;
  }
}

.desktopImage {
  display: block;
  max-width: 100%;
  height: auto;
}

.mobileImage {
  position: absolute;
  right: 0; bottom: 0;

  display: block;
  height: auto;
  max-width: 100%;
  width: 20%;

  margin-right: 4px;
  @include onLaptop {
    margin-right: 0;
  }
}

.divider {
  display: block;
  width: 75%;
  height: 1px;
  background-color: rgba(white, 0.5);
  margin-top: 40px;
  margin-bottom: 40px;
}

.projectInfo {
  width: 100%;
  order: 1;
  @include onLaptop {
    width: 33.33%;
    order: 2;
  }
}

.projectTitle {
  color: white;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: 0.15px;
  margin-bottom: 8px;
  @include onLaptop {
    font-size: 24px;
  }
}
.projectText {
  margin-bottom: 32px;
  @include onLaptop {
    margin-bottom: 40px;
    min-height: 200px;
  }
}

.navGroupDesktop,
.navGroupMobile {
  display: flex;
  align-items: center;
  gap: 8px;
}
.navButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border: 1px solid white;
  border-radius: 50%;
  color: white;
  &:hover {
    background-color: rgba(white, 0.1);
  }
  &:disabled {
    pointer-events: none;
    opacity: 0.3;
  }
}

.navGroupMobile {
  display: flex;
  justify-content: flex-end;
  @include onTablet {
    padding-right: 16px;
  }
  @include onLaptop {
    display: none;
    padding-right: 0;
  }
}

.navGroupDesktop {
  display: none;
  @include onLaptop {
    display: flex;
  }
}
