@import "../../../styles/utils/mixins";
@import "../../../styles/utils/variables";

.root {
  position: relative;

  padding-top: 24px;
  padding-bottom: 24px;
  @include onLaptop {
    padding-top: 96px;
    padding-bottom: 96px;
  }
}

.container {
  
}

.wrapper {
  display: flex;
  flex-direction: column;
  @include onLaptop {
    flex-direction: row;
  }
}

.wrapperLeft {
  flex: 1;
  position: relative;
}

.title {
  & > span {
    @include onLaptop {
      display: block;
    }
  }
}

.wrapperRight {
  flex: 2;
}

.serviceGroup {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
}
.serviceItem {
  display: flex;
  align-items: center;
  background-color: $secondary;
  box-shadow: 0px 8px 16px -5px rgba(black, 0.2);
  border-radius: 4px;
  padding: 12px 24px 12px 12px;
}
.serviceItemIcon {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background-color: rgba($primary, 0.12);
  border-radius: 50%;
  color: $primary;
  margin-right: 20px;
}
.serviceItemLabel {
  margin-bottom: 0;

  color: white;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.1px;
  @include onLaptop {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.15px;
  }
}

.mobileDots {
  display: flex;
  justify-content: flex-end;
  padding-top: 32px;
  @include onTablet {
    padding-right: 32px;
  }
  @include onLaptop {
    display: none;
  }
}

.laptopDots,
.desktopDots {
  position: absolute;
  left: 0; bottom: 0;
}
.laptopDots {
  display: none;
  @include onLaptop {
    display: block;
    margin-bottom: -112px;
    margin-left: -88px;
  }
  @include onDesktop {
    display: none;
  }
}
.desktopDots {
  display: none;
  @include onDesktop {
    display: block;
    margin-bottom: -112px;
    margin-left: -88px;
  }
}
