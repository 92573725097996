@import "../../../styles/utils/mixins";
@import "../../../styles/utils/variables";


.root {
  position: relative;
}

.container {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;

  @include onLaptop {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.text {
  text-align: center;
}

.text h2 {
  color: #fff;
  font-size: 20px;

  @include onTablet  {
    font-size: 22px;
  }

  @include onLaptop {
    font-size: 24px;
  }
}

.text p {
  color: #fff;
  font-size: 14px;

  @include onTablet  {
    font-size: 16px;
  }

  @include onLaptop {
    font-size: 18px;
  }
}
.images {
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 24px;

  @include onTablet {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.imageWrapper {
  position: relative;
  width: 100%;
  height: 200px;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;

  @include onTablet {
    max-width: calc(100% / 2 - 12px);
  }

  @include onLaptop {
    height: 250px;
    max-width: calc(100% / 4 - 18px);
  }

  &:hover .imageHover {
    opacity: 1;
  }
}

.imageHover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(black, 0.9);
  opacity: 0;
  transition: opacity 0.3s;

  &::after {
    content: '+';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgba(white, 0.9);
    font-size: 40px;
    font-weight: 600;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
