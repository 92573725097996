@import "../../styles/utils/mixins";
@import "../../styles/utils/variables";

.root {
  background-color: $secondary;
  position: fixed;
  top: 0; left: 0;
  width: 100%;
  z-index: 4;
}

.logo {
  display: block;
  cursor: pointer;

  img {
    display: block;
    height: 32px;
    @include onLaptop {
      height: 40px;
    }
  }
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  @include onLaptop {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.nav {
  display: none;
  flex-direction: column;
  position: fixed;
  top: 0; left: 0;
  width: 100%;
  height: calc(100% - 64px);
  background-color: $secondary;
  margin-top: 64px;
  overflow-y: auto;
  &.navIsOpen {
    display: flex;
  }
  @include onLaptop {
    display: flex;
    flex-direction: row;
    position: relative;
    width: auto;
    height: auto;
    background-color: initial;
    margin-top: 0;
    gap: 24px;
  }
}

.navItem {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  border-top: 1px solid rgba(white, 0.23);

  color: white;
  font-weight: 600;
  font-size: 34px;
  line-height: 42px;
  letter-spacing: 0.25px;

  padding: 16px 24px;

  @include onLaptop {
    border-top: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.1px;

    padding: 0;

    &:hover {
      cursor: pointer;
      color: $primary;
      &::after {
        opacity: 1;
      }
    }
  }
  
  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: $primary;
    transform: opacity 0.3s;
    opacity: 0;
  }
}
.navItemActive {
  color: $primary;
  &::after {
    opacity: 1;
  }
}

.buttonIcon {
  @include onLaptop {
    display: none;
  }
}
