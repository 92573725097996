// Base: Root styles
@import '../utils/variables';
@import '../utils/mixins';

*, *::before, *::after {
  box-sizing: border-box;
}

html {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 400;
}

body {
  background-color: #262E36; // $secondary;
  background-image: url('../../images/body-bg.jpg');
  background-size: cover;
  background-position: top center;
  background-repeat: repeat-y;
}

.page {
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  touch-action: none;

  @include onLaptop {
    overflow: auto;
  }
}

#root {
  overflow: hidden;
}

h1, h2, h3, h4, h5, h6, p {
  margin-top: 0;
}
a { text-decoration: none; }

button {
  background: none;
  border: none;
  outline: 0;
  font-family: 'Poppins';
  &:hover {
    cursor: pointer;
  }
}

input {
  font-family: 'Poppins';
}
