@import "../../styles/utils/mixins";
@import "../../styles/utils/variables";

.root {
  padding-top: 40px;
  padding-bottom: 40px;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.copy {
  color: white;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.17px;
  text-align: left;
  & > span {
    display: block;
    @include onTablet {
      display: inline-block;
    }
  }
}

.links {
  display: flex;
  align-items: center;
  gap: 40px;
}

.link {
  display: block;
  color: white;
  transition: color 0.3s;

  &:hover {
    color: $primary;
  }
}
