@font-face {
  font-family: 'MouseOver';
  src:  url('../../fonts/mouseover-icons/mouseover-icons.eot?yicx9');
  src:  url('../../fonts/mouseover-icons//mouseover-icons.eot?yicx9#iefix') format('embedded-opentype'),
    url('../../fonts/mouseover-icons//mouseover-icons.ttf?yicx9') format('truetype'),
    url('../../fonts/mouseover-icons//mouseover-icons.woff?yicx9') format('woff'),
    url('../../fonts/mouseover-icons//mouseover-icons.svg?yicx9#mouseover-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'MouseOver' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Close:before { content: "\e917"; }
.icon-MenuFilled:before { content: "\e918"; }
.icon-ChevronLeftOutlined:before { content: "\e914"; }
.icon-ChevronRightOutlined:before { content: "\e915"; }
.icon-ExpandMoreFilled:before { content: "\e916"; }
.icon-ArrowsClockwise:before { content: "\e900"; }
.icon-BigData:before { content: "\e901"; }
.icon-ChartLineUp:before { content: "\e902"; }
.icon-Check:before { content: "\e903"; }
.icon-CloudCheck:before { content: "\e904"; }
.icon-Code:before { content: "\e905"; }
.icon-Cookie:before { content: "\e906"; }
.icon-Cube:before { content: "\e907"; }
.icon-Desktop:before { content: "\e908"; }
.icon-DeviceMobile:before { content: "\e909"; }
.icon-Gear:before { content: "\e90a"; }
.icon-Heartbeat:before { content: "\e90b"; }
.icon-HouseLine:before { content: "\e90c"; }
.icon-Laptop:before { content: "\e90d"; }
.icon-Lightbulb:before { content: "\e90e"; }
.icon-LinkedIn:before { content: "\e90f"; }
.icon-MagnifyingGlass:before { content: "\e910"; }
.icon-ShoppingCart:before { content: "\e911"; }
.icon-Stack:before { content: "\e912"; }
.icon-UmbrellaSimple:before { content: "\e913"; }
