@import "../../../styles/utils/mixins";
@import "../../../styles/utils/variables";

.root {
  position: relative;

  padding-top: 24px;
  padding-bottom: 24px;
  @include onLaptop {
    padding-top: 64px;
    padding-bottom: 80px;
  }
}

.container {
  position: relative;
}

.customerGroup {
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
  z-index: 2;
  @include onLaptop {
    gap: 24px;
  }
}
.customerItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: $secondary;
  box-shadow: 0px 8px 16px -5px rgba(black, 0.2);
  border-radius: 4px;
  padding: 16px;
  &:hover {
    background-color: lighten($secondary, 5%);
  }
  @include onTablet {
    width: calc(50% - 4px);
  }
  @include onLaptop {
    width: calc(33.33% - 16px);
  }
}
.customerItemImage {
  display: block;
  height: 40px;
}

.mobileDots {
  display: flex;
  justify-content: flex-end;
  padding-top: 24px;
  @include onTablet {
    padding-top: 16px;
    padding-right: 48px;
  }
  @include onLaptop {
    display: none;
  }
}
.desktopDots {
  display: none;
  @include onLaptop {
    display: block;
    position: absolute;
    right: 0; top: 0;
    margin-top: 56px;
    margin-right: -56px;
  }
  @include onDesktop {
    margin-top: 64px;
    margin-right: -120px;
  }
}
