@import '../../styles/utils/mixins';
@import '../../styles/utils/variables';

.backdrop {
  position: fixed;
  top: 0; left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(white, 0.08);
  backdrop-filter: blur(40px);
  z-index: 9;
}

.dialog {
  position: fixed;
  top: 0; left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.dialogContent {
  position: relative;
  max-width: 700px;
  width: 100%;
  background-color: $secondary;
  box-shadow: 0px 8px 16px -5px rgba(black, 0.2);
  border-radius: 4px;
  padding: 48px 24px;
  margin-right: 24px;
  margin-left: 24px;
}

.dialogCloseButton {
  position: absolute;
  top: 0; right: 0;
  margin: 16px;
}

.iconWrapper {
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($primary, 0.12);
  border-radius: 50%;
  color: $primary;
  margin: 0 auto 32px;
}

.title {
  color: white;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 20px;
  text-align: center;
  @include onTablet {
    font-size: 34px;
    line-height: 42px;
    letter-spacing: 0.25px;
  }
}

.text {
  margin-bottom: 48px;
  text-align: center;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
}
