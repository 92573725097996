@import '../../styles/utils/mixins';

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  z-index: 0;
  @include onLaptop {
    gap: 48px;
  }
}
.dotWrapper {
  display: flex;
  gap: 24px;
  @include onLaptop {
    gap: 48px;
  }
}
.dot {
  display: block;
  width: 6px;
  height: 6px;
  background-color: #B59A6B;
  border-radius: 50%;
  @include onLaptop {
    width: 8px;
    height: 8px;
  }
}
.dotEmpty {
  background-color: transparent;
}
