@mixin onTablet {
  @media (min-width: 600px) {
    @content;
  }
}

@mixin onLaptop {
  @media (min-width: 1280px) {
    @content;
  }
}

@mixin onDesktop {
  @media (min-width: 1920px) {
    @content;
  }
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}


@mixin generateMargin($prefix, $property){
  $sizes: 0, 4, 8, 12, 16;
  $spacing: 1px;
  @for $i from 1 through length($sizes) {
    $size: nth($sizes, $i);
    .#{$prefix}-#{$i - 1} {
      #{$property}: $spacing * $size;
    }
  }
}

@mixin generateButton($mainColor, $textColor, $variant: 'contained'){
  color: $textColor;
  @if $variant == 'contained' {
    background-color: $mainColor;
  } @else {
    border: 1px solid $mainColor;
    background-color: transparent;
  }
  &:hover {
    @if $variant == 'contained' {
      background-color: darken($mainColor, 5%);
    } @else {
      background-color: rgba($mainColor, 0.1);
    }
  }
  &.disabled,
  &:disabled {
    border-color: rgba(white, 0.12);
    color: rgba(white, 0.3);
    @if $variant == 'contained' {
      background-color: rgba(white, 0.12);
    }
    pointer-events: none;
  }
}
