@import "../../../styles/utils/mixins";
@import "../../../styles/utils/variables";


.root {
  position: relative;
}

.container {
  position: relative;
  padding-top: 240px;
  padding-bottom: 40px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 40px;

  @include onLaptop {
    flex-direction: row;
    padding-top: 60px;
    padding-bottom: 30px;
  }
}

.text {
  width: 100%;

  @include onLaptop {
    width: 50%;
    padding-left: 190px;
  }
}

.text h2 {
  color: #fff;
  font-size: 18px;
  @include onTablet {
    font-size: 20px;
  }
  @include onLaptop {
    font-size: 24px;
  }
}

.text p {
  color: #fff;
  font-size: 14px;
  @include onTablet {
    font-size: 16px;
  }
  @include onLaptop {
    font-size: 18px;
  }
}

.image {
  order: -1;
  width: 100%;
  max-width: 300px;
  object-fit: cover;
  box-shadow: 20px -20px 5px rgba($secondary, 0.5);
  border: 2px solid rgba(255,255,255,0.6);
  padding: 4px;
  margin-right: 32px;

  @include onLaptop {
    position: absolute;
    right: 0;
    top: -33%;
    max-width: 320px;
    order: 0;
  }
}
