@import "../../../styles/utils/mixins";
@import "../../../styles/utils/variables";


.root {
  height: 80vh;
  position: relative;
  background-size: cover;
  background-position: 50% 60%;
  @include onLaptop {
    background-position: 0 50%;
  }
}

.container {
  position: relative;
  height: 100%;
  padding: 72px 24px 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  @include onLaptop {
    padding: 80px 60px 0;
    justify-content: flex-start;
  }
}

.bannerText {
  background-color: $secondary;
  width: 100%;
  max-width: 600px;
  padding: 32px 24px;
  border: 1px solid rgba(255,255,255,0.6);
  transform: translateY(50%);

  &::after {
    content: '';
    width: 60px;
    height: 8px;
    background-color: #fff;
    position: absolute;
    top: 30px;
    left: -30px;
    display: none;
  }

  @include onLaptop {
    padding: 60px 24px;
    border-bottom: none;
    transform: translateY(0);

    &::after {
      display: block;
    }
  }
}

.bannerText h2 {
  color: #fff;
  font-size: 32px;
  font-weight: 500;

  @include onTablet {
    font-size: 40px;
  }

  @include onLaptop {
    font-size: 35px;
  }
}

.bannerText p {
  color: #fff;
  font-size: 16px;

  @include onTablet {
    font-size: 18px;
  }

  @include onLaptop {
    font-size: 20px;
  }
}
