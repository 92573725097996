// Components: Controls

.control-group {
  position: relative;
  margin-bottom: 8px;
  @include onTablet {
    padding-bottom: 28px;
  }
  &.has-error {
    .control-label {

    }
    .control {
      border-color: $error;
    }
    .control-helper {
      color: $error;
    }
  }
}

.control-label {
  display: block;
  color: rgba(white, 0.7);
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.15px;
  margin-bottom: 8px;
}
textarea.control {
  resize: none;
}
.control {
  width: 100%;
  background-color: $secondary;
  box-shadow: 0px 4px 20px rgba(207, 176, 122, 0.2);
  border: 2px solid rgba(white, 0.3);
  border-radius: 4px;
  color: white;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  padding: 10px 16px;
  &:focus {
    outline: none;
    border-color: $primary;
  }
  &::placeholder {
    color: rgba(white, 0.5);
  }
}
.control-helper {
  display: block;
  width: 100%;
  color: white;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.4px;
  margin-top: 8px;
  @include onTablet {
    position: absolute;
    left: 0; bottom: 0;
    margin-top: 0;
  }
}
