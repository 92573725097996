@import "../../../styles/utils/mixins";
@import "../../../styles/utils/variables";


.root {
  display: flex;
  justify-content: center;
  align-items: center;
  

  position: relative;

  padding-top: 72px;
  padding-bottom: 64px;

  @include onLaptop {
    min-height: 100vh;

    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  @include onLaptop {
    flex-direction: row;
  }
}

.bannerText {
  order: 2;
  @include onLaptop {
    padding-right: 64px;
    order: initial;
  }
}

.title {
  font-size: 34px;
  line-height: 42px;
  letter-spacing: 0.25px;
  margin-bottom: 24px;
  @include onTablet {
    font-size: 48px;
    line-height: 56px;
  }
  @include onLaptop {
    font-size: 88px;
    line-height: 103px;
    letter-spacing: -1.5px;
    margin-bottom: 32px;
  }
}

.text {
  margin-bottom: 32px;
  @include onLaptop {
    margin-bottom: 64px;
    max-width: 75%;
  }
}

.bannerImageWrapper {
  align-self: flex-end;
  position: relative;
  order: 1;
  @include onLaptop {
    align-self: initial;
    flex-shrink: 0;
    order: initial;
  }
}

.bannerImage {
  margin-bottom: 20px;
  max-width: 220px;
  @media screen and (min-width: 480px) {
    max-width: 265px;
  }
  @include onTablet {
    max-width: 306px;
  }
  @include onLaptop {
    max-width: 100%;
    margin-bottom: 0;
  }
}

.mobileDots,
.tabletDots,
.desktopDots {
  position: absolute;
  left: 0; bottom: 0;
}

.mobileDots {
  margin-left: -44px;
  margin-bottom: 24px;

  @include onTablet {
    display: none;
  }
}
.tabletDots {
  display: none;

  margin-left: -124px;
  margin-bottom: 60px;

  @include onTablet {
    display: block;
  }

  @include onLaptop {
    display: none;
  }
}
.desktopDots {
  display: none;

  margin-left: -60px;
  margin-bottom: -37px;

  @include onLaptop {
    display: block;
  }
}

.buttonGroup {
  display: flex;
  gap: 8px;
}
