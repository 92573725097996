// Helper class
@import './utils/mixins';

// Display
.block { display: block; }

// Margin
@include generateMargin(mb, margin-bottom);

// Text align
.text-center { text-align: center; }

.image-fluid {
  display: block;
  max-width: 100%;
  height: auto;
}

.hidden { display: none !important; }
