@import '../../styles/utils/variables';
@import '../../styles/utils/mixins';

.root {
  display: flex;
  flex-direction: column;

  position: fixed;
  left: 0; right: 0; bottom: 0;

  background: $secondary;
  box-shadow: 0px 4px 20px rgba(207, 176, 122, 0.2);
  border-radius: 4px;

  margin: 16px;
  padding: 16px;

  z-index: 5;
  @include onTablet {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.info {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  @include onTablet {
    margin-bottom: 0;
    padding-right: 24px;
  }
}

.iconWrapper {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background-color: rgba($primary, 0.12);
  border-radius: 50%;
  color: $primary;
  margin-right: 16px;
  @include onTablet {
    margin-right: 24px;
  }
}

.text {
  color: white;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.17px;
  margin-bottom: 0;
  @include onLaptop {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
}

.button {
  width: 100%;
  @include onTablet {
    width: initial;
  }
}
