@import "../../../styles/utils/mixins";
@import "../../../styles/utils/variables";

.root {
  display: flex;
  justify-content: center;
  align-items: center;
  // min-height: 100vh;

  padding-top: 24px;
  padding-bottom: 24px;
  @include onLaptop {
    padding-top: 96px;
    padding-bottom: 96px;
  }
}

.container {
  position: relative;
}

.title {
  margin-bottom: 32px;
  @include onLaptop {
    margin-bottom: 48px;
  }
}

.form {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.col {
  width: 100%;
  @include onTablet {
    width: calc(50% - 8px);
  }
  @include onLaptop {
    width: calc(25% - 12px);
  }
}
.colTextArea {
  @include onTablet {
    width: 100%;
  }
  @include onLaptop {
    width: calc(50% - 8px);
  }
}

.submitWrapper {
  text-align: right;
}
.submit {
  width: 100%;
  @include onTablet {
    width: 50%;
  }
}

.mobileDots {
  display: flex;
  justify-content: flex-end;
  padding-top: 32px;
  @include onTablet {
    padding-right: 32px;
  }
  @include onLaptop {
    display: none;
  }
}

.mobileDots {
  display: block;
  padding-left: 44px;
  @include onTablet {
    display: none;
  }
}
.tabletDots {
  display: none;
  @include onTablet {
    display: block;
    position: absolute;
    left: 0; bottom: 0;
    margin-bottom: -70px;
    margin-left: 48px;
  }
  @include onLaptop {
    display: none;
  }
}
.desktopDots {
  display: none;
  @include onLaptop {
    display: block;
    position: absolute;
    left: 0; bottom: 0;
    margin-bottom: -24px;
    margin-left: -64px;
  }
}
