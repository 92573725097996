.loader,
.loader:after {
  border-radius: 50%;
  width: 26px;
  height: 26px;
}
.loader {
  margin: 0 auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 4px solid rgba(white, 0.2);
  border-right: 4px solid rgba(white, 0.2);
  border-bottom: 4px solid rgba(white, 0.2);
  border-left: 4px solid white;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
}

@keyframes load8 {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}