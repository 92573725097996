// Components: Buttons

.button {
  border-radius: 4px;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  letter-spacing: 0.1px;
  padding: 8px 22px;
  text-align: center;
  white-space: nowrap;
}

.button-contained {
  @include generateButton($primary, $secondary);
}

.button-outlined {
  padding-top: 7px;
  padding-bottom: 7px;
  @include generateButton($primary, $primary, 'outlined');
}

.button-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  color: white;
  padding: 0;
  &:hover {
    background-color: rgba(white, 0.05);
  }
  @include onLaptop {
    width: 48px;
    height: 48px;
  }
}
