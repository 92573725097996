// Components: Typography
@import '../utils/mixins';

// Page typography
// Subtitle
.subtitle {
  color: rgba(white, 0.7);
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 1px;
  margin-bottom: 8px;
  text-transform: capitalize;
  @include onDesktop {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 1.5px;
  }
}
// Title
.title {
  color: white;
  font-size: 24px;
  font-weight: 300;
  line-height: 32px;
  margin-bottom: 16px;
  & > .highlight {
    color: $primary;
  }
  @include onTablet {
    font-size: 34px;
    line-height: 42px;
    letter-spacing: 0.25px;
  }
  @include onLaptop {
    font-size: 60px;
    line-height: 72px;
    letter-spacing: -0.5px;
    margin-bottom: 32px;
  }
  @include onDesktop {
    letter-spacing: -0.5px;
  }
}
// Default text
.text {
  color: rgba(white, 0.7);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.17px;
  @include onTablet {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.17px;
  }
}