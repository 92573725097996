@import "../../../styles/utils/mixins";
@import "../../../styles/utils/variables";


.root {
  position: relative;
}

.container {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  @include onLaptop {
    flex-direction: row;
    padding-top: 60px;
    padding-bottom: 60px;
    gap: 40px;
  }
}

.text {
  width: 100%;
  @include onLaptop {
    width: 50%;
  }
}

.text h2 {
  color: #fff;
  font-size: 18px;

  @include onTablet {
    font-size: 20px;
  }

  @include onLaptop {
    font-size: 24px;
  }
}

.text p {
  color: #fff;
  font-size: 14px;

  @include onTablet {
    font-size: 16px;
  }

  @include onLaptop {
    font-size: 18px;
  }
}

.image {
  order: 1;
  width: 100%;
  height: 250px;
  object-fit: cover;
  box-shadow: -20px -20px 5px rgba($secondary, 0.6);
  border-radius: 12px;

  @include onTablet {
    height: 400px;
  }

  @include onLaptop {
    order: 0;
    max-width: 50%;
    height: 350px;
  }
}
