@import '../../styles/utils/mixins';
@import '../../styles/utils/variables';

.backdrop {
  position: fixed;
  top: 0; left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(black, 0.2);
  backdrop-filter: blur(4px);
  z-index: 9;
}

.dialog {
  position: fixed;
  top: 0; left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.dialogContent {
  position: relative;
  width: 100%;
  max-width: 900px;
  height: 600px;
  max-height: 80vh;
  background-color: $secondary;
  box-shadow: 0px 8px 16px -5px rgba(black, 0.2);
  border-radius: 4px;
  padding: 80px 24px 24px;
  margin-right: 24px;
  margin-left: 24px;
  border: 1px solid #f5f5f5;
}

.dialogCloseButton {
  position: absolute;
  top: 0; right: 0;
  margin: 16px;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
